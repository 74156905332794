import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { forwardRef } from 'react';

const BuildingsIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <SvgIcon
      ref={ref}
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <path
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM5 19V5H11V19H5ZM19 19H13V13H19V19ZM19 11H13V5H19V11Z"
        fill="#939392"
      />
      <path d="M11 13H5V11H11V13Z" fill="#939392" />
    </SvgIcon>
  );
});

export default BuildingsIcon;
