import { useState, useCallback } from 'react';
import {
  Filters,
  UseFilterParams,
  DefaultDataItem,
  FilterValue,
} from './useFilter.types';
import {
  processFilter,
  initializeFiltersFromUrlState,
  queryStringToUrlState,
  generateQueryString,
} from './useFilter.utils';
import { useUrlState } from 'hooks/useUrlState';
import { camelCaseToDoubleUnderscore } from './useFilter.utils';

/**
 * Custom hook to manage filters, including URL state sync.
 *
 * @template T - The type of data items being filtered.
 * @param  filterAliasMap - An object of key-value pairs where the key is an alias and the value is an array of properties
 *  the alias maps to as well as the condition upon which the filter results of these multiple properties are combined i.e AND, OR.
 * @returns {Object} Object containing methods and states to manage filters.
 */

export const useFilter = <T extends object = DefaultDataItem>({
  filterAliasMap,
  isValidFilterProperty = (property: string) => !!property,
}: UseFilterParams = {}): {
  updateFilters: (newFilters: Filters) => void;
  clearFilter: (filterKey: string) => void;
  getFilteredData: (data: T[]) => T[];
  clearAllFilters: () => void;
  getFilterValues: (filterKeys: string | string[]) => FilterValue[];
  getFilters: () => Filters;
} => {
  const { setUrlState, getUrlState, clearUrlState } = useUrlState();
  const urlState = getUrlState();
  const [filters, setFilters] = useState<Filters>(() =>
    initializeFiltersFromUrlState(
      urlState,
      isValidFilterProperty,
      filterAliasMap
    )
  );

  const updateFilters = useCallback(
    (newFilters: Filters) => {
      const updatedFilters = { ...filters, ...newFilters };
      const queryString = generateQueryString(updatedFilters);
      setUrlState(queryStringToUrlState(queryString));
      setFilters(updatedFilters);
    },
    [filters, setUrlState]
  );

  const clearFilter = useCallback(
    (filterKey: string) => {
      const urlKey = camelCaseToDoubleUnderscore(filterKey); // Convert filter key to URL parameter format
      clearUrlState([urlKey]);
      const { [filterKey]: _, ...remainingFilters } = filters; // Remove the filter from local state
      setFilters(remainingFilters);
    },
    [filters, clearUrlState]
  );

  const clearAllFilters = useCallback(() => {
    clearUrlState();
    setFilters({});
  }, [clearUrlState]);

  const getFilterValues = useCallback(
    (filterKeys: string | string[]) => {
      const keys = Array.isArray(filterKeys) ? filterKeys : [filterKeys];
      return keys
        .map((key) => filters[key]?.value)
        .filter((value) => value !== undefined);
    },
    [filters]
  );

  const getFilters = useCallback(() => {
    return { ...filters }; // Shallow copy of filters
  }, [filters]);

  const getFilteredData = useCallback(
    (data: T[]) =>
      data?.filter((item) =>
        Object.entries(filters).every(
          ([_, { value: filterValue, compareFnKey, filterAlias, property }]) =>
            processFilter(
              item,
              filterValue,
              compareFnKey,
              filterAliasMap,
              filterAlias,
              property
            )
        )
      ),
    [filters, filterAliasMap]
  );

  return {
    getFilters,
    getFilteredData,
    updateFilters,
    clearFilter,
    clearAllFilters,
    getFilterValues,
  };
};
