import { SelectField } from 'components/inputs/SelectField';
import { SearchField } from 'components/inputs/SearchField';
import { PROJECT_STATUS } from 'pages/Projects/Projects.utils';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

//TODO: fix types

export const ProjectsFilter = ({
  getFilterValues,
  updateFilters,
  clearFilter,
}) => {
  const projectSearchValue = getFilterValues?.('projectContains');

  const [statusContainsValue] = getFilterValues?.('statusContains');

  const selectValue = statusContainsValue
    ? Array.isArray(statusContainsValue)
      ? statusContainsValue
      : [statusContainsValue]
    : [];

  const { t } = useTranslation();

  // Handle search input change
  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.trim() === '') {
      // If the input is empty after backspace, clear the filter
      clearFilter('projectContains');
    } else {
      // Otherwise, update the filter with the new search value
      updateFilters({
        projectContains: {
          value: searchValue,
          filterAlias: 'project',
          compareFnKey: 'contains',
        },
      });
    }
  };

  // Handle project status filter change
  const handleStatusChange = (e) => {
    const selectedStatuses = e.target.value;
    if (!selectedStatuses || !selectedStatuses?.length) {
      clearFilter('statusContains');
    } else {
      updateFilters({
        statusContains: {
          value: selectedStatuses,
          property: 'status',
          compareFnKey: 'contains',
        },
      });
    }
  };

  const projectStatusOptions = Object.values(PROJECT_STATUS).map((status) => ({
    value: status,
    label: status.charAt(0).toUpperCase() + status.slice(1),
  }));

  return (
    <Grid item container xs={12} md={6} spacing={2}>
      <Grid item xs={12} sm={6}>
        <SearchField
          placeholder={t('projects:overview.filterSection.searchProject')}
          onChange={handleSearchChange}
          onClear={() => clearFilter('projectContains')}
          defaultValue={projectSearchValue}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          value={selectValue}
          onChange={handleStatusChange}
          options={projectStatusOptions}
          multiple
          placeholder={t('projects:overview.filterSection.selectStatus')}
          label="Status"
        />
      </Grid>
    </Grid>
  );
};
