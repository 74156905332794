import {
  generatePath,
  useNavigate,
  useLocation,
  useParams,
  Outlet,
} from 'react-router';
import { UnitDetailTopbar } from 'components/layout/UnitDetailTopbar';
import { determineConservativeAvailableRange } from 'utils/units';
import useProject from 'hooks/useProject';
import { useTimerange } from 'hooks/useTimerange';

export const ProjectMonitoringLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectSlug, unitExternalIdentifier } = useParams();
  const {
    projectUnits: units,
    projectUnitInstances: instances,
    project,
  } = useProject();

  const splitPath = location.pathname.split('/');
  const postFix = splitPath[splitPath.length - 1];

  const availableRange = determineConservativeAvailableRange(
    project,
    instances
  );

  const { range, ...timerangeProps } = useTimerange(availableRange);

  function createOnChangeUnitFunction(pathPostFix) {
    function onChangeUnit(unit) {
      const newPath = generatePath(
        '/projects/:projectSlug/monitoring/:unitExternalIdentifier' +
          `/${pathPostFix}`,
        {
          projectSlug,
          unitExternalIdentifier: unit.externalIdentifier,
        }
      );
      navigate(newPath, { replace: true });
    }
    return onChangeUnit;
  }

  const selectedAsset = units.find(
    (unit) => unit.externalIdentifier === unitExternalIdentifier
  );

  return (
    <>
      <UnitDetailTopbar
        unitSelectorProps={{
          units,
          onChange: createOnChangeUnitFunction(postFix),
          selectedUnitExtId: unitExternalIdentifier,
        }}
        timerangePickerProps={{
          range,
          availableRange,
          ...timerangeProps,
        }}
        showRangePicker={!location.pathname.includes('asset')}
      />
      <Outlet
        context={{
          selectedAsset,
          range,
        }}
      />
    </>
  );
};
