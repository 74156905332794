import Sidebar from 'components/layout/Sidebar';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Stack, Box } from '@mui/material';
import { Breadcrumb } from '../Breadcrumb';

export const SidebarLayout = () => {
  const { openSideBar, setOpenSideBar } = useOutletContext();
  return (
    <Stack direction="row" justifyContent="space-between" spacing={3}>
      <Sidebar open={openSideBar} setOpen={setOpenSideBar} />
      <Box sx={{ minWidth: '500px', width: '95%' }}>
        <Breadcrumb />
        <Outlet />
      </Box>
    </Stack>
  );
};
