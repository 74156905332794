import { ReactNode, MouseEvent } from 'react';
import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  ToggleButton,
  ToggleButtonProps,
} from '@mui/material';

type ButtonValue = string | number | boolean;

type Button = {
  value: ButtonValue;
  content: ReactNode;
  buttonProps?: Partial<ToggleButtonProps>;
};

type ToggleButtonGroupProps = {
  buttons: Array<Button>;
  selectedValue: ButtonValue | ButtonValue[];
  onChange: (value: ButtonValue | ButtonValue[] | null) => void;
  exclusive?: boolean;
} & Partial<MuiToggleButtonGroupProps>;

export const ToggleButtonGroup = ({
  buttons,
  selectedValue,
  onChange,
  exclusive = false,
  ...props
}: ToggleButtonGroupProps) => {
  const handleChange = (
    _: MouseEvent<HTMLElement>,
    newValue: ButtonValue | ButtonValue[]
  ) => {
    if (newValue === selectedValue) {
      onChange(null);
    } else {
      onChange(newValue);
    }
  };

  const isSelected = (value: ButtonValue) =>
    Array.isArray(selectedValue)
      ? selectedValue?.includes(value)
      : selectedValue === value;

  return (
    <MuiToggleButtonGroup
      value={selectedValue}
      exclusive={exclusive}
      onChange={handleChange}
      aria-label="toggle-button-group"
      {...props}
    >
      {buttons.map(({ value, content, buttonProps }, index) => {
        return (
          <ToggleButton
            size="small"
            key={`unit-${index}`}
            value={value}
            selected={isSelected(value)}
            sx={{ color: (theme) => theme.palette.grey[600] }}
            {...(buttonProps ?? {})}
          >
            {content}
          </ToggleButton>
        );
      })}
    </MuiToggleButtonGroup>
  );
};
