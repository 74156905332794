import { Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from './contexts/Auth0Context';
import { SidebarProvider } from './contexts/SidebarContext';
import { createTheme } from './theme';
import useSettings from './hooks/useSettings';
import { browserRouter } from './routes';
import yupValidationMessages from './utils/yupValidationMessages';
import { LoadingScreen } from './components/fallback/LoadingScreen';
import ErrorFallback from './components/fallback/ErrorFallback';
import { ProductionAmplitudeProvider } from 'contexts/AmplitudeContext';

import packageJson from '../package.json';

const release = packageJson.version;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.NODE_ENV,
  release,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnWindowFocus: false,
      staleTime: 15 * 1000, // ms
      retry: 3,
    },
  },
});

/* Disable react devtools in production. */
if (
  process.env.NODE_ENV !== 'development' &&
  typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object'
) {
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () {};
}

export default function App() {
  const { settings } = useSettings();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });
  yupValidationMessages();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ProductionAmplitudeProvider>
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <HelmetProvider>
              <SnackbarProvider dense maxSnack={3}>
                <QueryClientProvider client={queryClient}>
                  <AuthProvider>
                    <SidebarProvider>
                      <Helmet
                        defaultTitle="Greener Projects"
                        titleTemplate="%s | Greener Projects"
                      />
                      <Suspense fallback={<LoadingScreen />}>
                        <RouterProvider
                          router={browserRouter}
                          fallbackElement={<LoadingScreen />}
                        />
                      </Suspense>
                    </SidebarProvider>
                  </AuthProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </SnackbarProvider>
            </HelmetProvider>
          </Sentry.ErrorBoundary>
        </ProductionAmplitudeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
