import { useEffect } from 'react';
import nprogress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3),
  margin: 'auto',
}));

export function LoadingScreen() {
  useEffect(() => {
    nprogress.start();

    return () => {
      nprogress.done();
    };
  }, []);

  return (
    <StyledDiv data-testid="loading-screen">
      <Box>
        <CircularProgress />
      </Box>
    </StyledDiv>
  );
}
