import { ReactNode } from 'react';
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';

type DefaultOption = {
  value: string | number;
  label: string;
};

type SelectFieldProps<T = DefaultOption> = {
  label?: string;
  value: string | string[] | number | number[];
  onChange: (
    event: SelectChangeEvent<string | number | string[] | number[]>
  ) => void;
  options: T[];
  multiple?: boolean;
  renderOption?: (option: T) => ReactNode;
  getOptionLabel?: (option: T) => string;
  getOptionValue?: (option: T) => string | number;
};

// Type guard to check if the option is a DefaultOption
const isDefaultOption = (option: any): option is DefaultOption =>
  option &&
  typeof option.label === 'string' &&
  (typeof option.value === 'string' || typeof option.value === 'number');

export const SelectField = <T = DefaultOption,>({
  value,
  onChange,
  options,
  label = 'Select',
  multiple = false,
  placeholder = '',
  renderOption,
  getOptionLabel = (option: T) => (isDefaultOption(option) ? option.label : ''),
  getOptionValue = (option: T) => (isDefaultOption(option) ? option.value : ''),
  ...props
}: SelectFieldProps<T> & { placeholder: string }) => {
  const isValueArray = (optionValue: string | number) => {
    // Type guard to ensure value and optionValue have the same type
    if (Array.isArray(value)) {
      if (typeof optionValue === 'string') {
        const stringArray = value as string[];
        return stringArray.includes(optionValue);
      } else if (typeof optionValue === 'number') {
        const numberArray = value as number[];
        return numberArray.includes(optionValue);
      }
    }
    return false;
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink id="select-label">
        {label}
      </InputLabel>
      <Select
        value={value}
        displayEmpty
        labelId="select-label"
        onChange={onChange}
        multiple={multiple}
        notched
        label={label}
        sx={{ height: '40px' }}
        inputProps={{ placeholder }}
        renderValue={(selected) => {
          if (!selected || (Array.isArray(selected) && !selected?.length)) {
            return <Typography color="text.tertiary">{placeholder}</Typography>;
          }
          if (multiple && Array.isArray(selected)) {
            return (
              <Typography>
                {selected
                  .map((val) =>
                    options.find((option) => getOptionValue(option) === val)
                      ? getOptionLabel(
                          options.find(
                            (option) => getOptionValue(option) === val
                          )!
                        )
                      : ''
                  )
                  .join(', ')}
              </Typography>
            );
          }
          const selectedOption = options.find(
            (option) => getOptionValue(option) === selected
          );
          return selectedOption ? getOptionLabel(selectedOption) : '';
        }}
        fullWidth
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={getOptionValue(option)} value={getOptionValue(option)}>
            {multiple ? (
              <>
                <Checkbox checked={isValueArray(getOptionValue(option))} />
                <ListItemText
                  primary={
                    renderOption ? renderOption(option) : getOptionLabel(option)
                  }
                />
              </>
            ) : renderOption ? (
              renderOption(option)
            ) : (
              getOptionLabel(option)
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
