import type { Project } from 'types';
import { compareAsc, compareDesc } from 'date-fns';
import { differenceInCalendarDays } from 'date-fns';

export const PROJECT_STATUS = {
  COMPLETED: 'completed',
  ONGOING: 'ongoing',
  UPCOMING: 'upcoming',
};

export const PROJECTS_VIEWS = {
  CARD: '/projects',
  LIST: '/projects/overview',
  MAP: '/projects/map',
};

export function sortProjectsByDescendingTsEnd(data: Project[]): Project[] {
  return data
    .slice()
    .sort((a, b) => compareDesc(new Date(a.tsEnd), new Date(b.tsEnd)));
}

export function sortProjectsByAscendingTsStart(data: Project[]): Project[] {
  return data
    .slice()
    .sort((a, b) => compareAsc(new Date(a.tsStart), new Date(b.tsStart)));
}

/**
 * @param  data - Array with the projects the user is allowed to see
 * @returns  Array of the last past projects the user is allowed to see
 */
export function getPastProjects(data: Project[]): Project[] {
  const filteredProjects = data
    .filter((project) => project.tsEnd !== null)
    .filter(
      (project) => compareAsc(new Date(project.tsEnd), new Date()) === -1
    );

  const sortedProjects = sortProjectsByDescendingTsEnd(filteredProjects);

  return sortedProjects.map((project) => ({
    ...project,
    status: PROJECT_STATUS.COMPLETED,
  }));
}

/**
 * @param  data - Array with the projects the user is allowed to see
 * @returns  Array of the first current projects the user is allowed to see
 */
export function getCurrentProjects(data: Project[]): Project[] {
  const today = new Date();

  return data
    .filter((project) => {
      const tsStart = new Date(project.tsStart);
      const tsEnd = project.tsEnd ? new Date(project.tsEnd) : null;

      return tsStart <= today && (!tsEnd || tsEnd > today);
    })
    .sort((a, b) => {
      const diffA = Math.abs(new Date(a.tsStart).getTime() - today.getTime());
      const diffB = Math.abs(new Date(b.tsStart).getTime() - today.getTime());
      return diffA - diffB;
    })
    .map((project) => ({
      ...project,
      status: PROJECT_STATUS.ONGOING,
    }));
}

/**
 * @param  data - Array with the projects the user is allowed to see
 * @returns  Array of the first coming projects the user is allowed to see
 */
export function getComingProjects(data: Project[]): Project[] {
  const sortedProjects = sortProjectsByAscendingTsStart(data);
  const filtered = sortedProjects.filter(
    (project) => compareAsc(new Date(project.tsStart), new Date()) === 1
  );
  return filtered.map((project) => {
    const startDate = new Date(project.tsStart);
    const daysUntilStart = differenceInCalendarDays(startDate, new Date());
    return {
      ...project,
      status: PROJECT_STATUS.UPCOMING,
      daysUntilStart,
    };
  });
}
