import { useRef, ChangeEvent } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  TextFieldProps,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';

type SearchFieldProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  placeholder?: string;
} & Partial<TextFieldProps>;

export const SearchField = ({
  onChange,
  onClear,
  defaultValue = '',
  placeholder = 'Search...',
  ...props
}: SearchFieldProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      if (onClear) {
        onClear();
      }
    }
  };

  return (
    <TextField
      onChange={onChange}
      size="small"
      fullWidth
      defaultValue={defaultValue}
      label={t('main:search.search')}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClearInput}
              edge="end"
              size="small"
              style={{
                visibility: inputRef.current?.value ? 'visible' : 'hidden',
              }}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder={placeholder}
      inputRef={inputRef}
      {...props}
    />
  );
};
