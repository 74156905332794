import { useState } from 'react';
import { LoadingScreen } from 'components/fallback/LoadingScreen';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { Stack } from '@mui/material';
import { Topbar } from '../Topbar';
import SearchDrawer from '../Topbar/SearchDrawer';
import '../../translations';
import { PWAWrapper } from 'PWAWrapper';

const baseMixin = {
  overflowX: 'hidden',
  overflowY: 'scroll',
  m: 2,
  mt: 4,
  p: 2,
  height: '100%',
  transition: 'all 500ms ease 0s',
};

export const MainLayout = () => {
  const [openSideBar, setOpenSideBar] = useState(false);
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState(false);

  return (
    <PWAWrapper>
      <CssBaseline />
      <Topbar
        toggleSidebar={() => setOpenSideBar(!openSideBar)}
        openSearchDrawer={setIsSearchDrawerOpen}
      />
      <SearchDrawer
        data-cy="search-drawer"
        isSearchDrawerOpen={isSearchDrawerOpen}
        setIsSearchDrawerOpen={setIsSearchDrawerOpen}
      />
      <Suspense fallback={<LoadingScreen />}>
        <Stack sx={baseMixin} spacing={2}>
          <Outlet context={{ openSideBar, setOpenSideBar }} />
        </Stack>
      </Suspense>
    </PWAWrapper>
  );
};
