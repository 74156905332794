import {
  Filters,
  CompareFnKey,
  FilterAliasMap,
  FilterValue,
  CompareFn,
} from './useFilter.types';
//@ts-expect-error lodash types
import { capitalize, get } from 'lodash';

const compareFnKeys = ['eq', 'le', 'ge', 'gt', 'lt', 'contains'];

/**
 * Constructs the filter key based on the property and comparison function key.
 *
 * @param {string} property - The filter property name.
 * @param {string} compareFnKey - The comparison function key.
 * @returns {string} The constructed filter key.
 */
export const getFilterKey = (property: string, compareFnKey: string) =>
  `${property}${capitalize(compareFnKey)}`;

/**
 * Converts camelCase strings to double underscore format (e.g., camelCase -> camel__Case).
 *
 * @param {string} str - The string to convert.
 * @returns {string} The converted string.
 */
export const camelCaseToDoubleUnderscore = (str: string) => {
  return str.replace(/([A-Z])/g, '__$1').toLowerCase();
};

const isCompareFnKey = (value: string): value is CompareFnKey => {
  return compareFnKeys.includes(value);
};

// Generates a query string from the given filters
export const generateQueryString = (filters: Filters): string => {
  const searchParams = new URLSearchParams();

  for (const { value, compareFnKey, filterAlias, property } of Object.values(
    filters
  )) {
    if (value || (Array.isArray(value) && !!value.length)) {
      const queryKey = filterAlias
        ? `${filterAlias}__${compareFnKey}`
        : `${property}__${compareFnKey}`;
      searchParams.set(queryKey, value.toString());
    }
  }
  return searchParams.toString();
};

//Transform to url state object
export const queryStringToUrlState = (
  queryString: string
): Record<string, string> => {
  const params = new URLSearchParams(queryString);
  const state: Record<string, string> = {};
  params.forEach((value, key) => {
    state[key] = value;
  });
  return state;
};

// Default comparison functions for each type of operation (client filtering)
export const compareFunctions: Record<string, CompareFn> = {
  eq: (itemValue, filterValue) => {
    // Handle array comparisons
    if (Array.isArray(itemValue) || Array.isArray(filterValue)) {
      if (Array.isArray(itemValue) && Array.isArray(filterValue)) {
        return itemValue.some((val) => filterValue.includes(val));
      }
      return false;
    }
    if (typeof itemValue === 'string' && typeof filterValue === 'string') {
      return itemValue.toLowerCase() === filterValue.toLowerCase();
    }
    return itemValue === filterValue;
  },
  ge: (itemValue, filterValue) => {
    if (!Array.isArray(itemValue) && !Array.isArray(filterValue)) {
      return itemValue >= filterValue;
    }
    return false;
  },
  le: (itemValue, filterValue) => {
    if (!Array.isArray(itemValue) && !Array.isArray(filterValue)) {
      return itemValue <= filterValue;
    }
    return false;
  },
  contains: (itemValue, filterValue) => {
    if (typeof itemValue === 'string' && typeof filterValue === 'string') {
      return itemValue.toLowerCase().includes(filterValue.toLowerCase());
    }
    if (Array.isArray(filterValue)) {
      return filterValue.includes(itemValue as string);
    }
    return false;
  },
};

export const initializeFiltersFromUrlState = (
  urlState: Record<string, string>,
  isValidFilterProperty: (properties: string) => boolean,
  filterAliasMap?: FilterAliasMap
) => {
  const newFilters: Filters = {};

  Object.entries(urlState).forEach(([key, value]) => {
    const [aliasOrProperty, compareFnKey] = key.split('__');

    // If the value is a comma-separated string, split it into an array
    const parsedValue = value.includes(',') ? value.split(',') : value;

    // Check if this is an alias
    const filterConfig = filterAliasMap?.[aliasOrProperty];

    const properties = filterConfig ? filterConfig.properties : aliasOrProperty;
    const condition = filterConfig ? filterConfig.condition : 'AND'; // Default to AND if no condition

    if (
      isValidFilterProperty(aliasOrProperty) &&
      isCompareFnKey(compareFnKey)
    ) {
      const filterKey = getFilterKey(aliasOrProperty as string, compareFnKey);
      newFilters[filterKey] = {
        value: parsedValue,
        property: properties,
        compareFnKey,
        filterAlias:
          aliasOrProperty !== properties ? aliasOrProperty : undefined, // Store the alias if it's different
        condition,
      };
    }
  });
  return newFilters;
};

//frontend filtering utils
export const filterSingleItem = (
  itemValue: FilterValue,
  filterValue: FilterValue,
  compareFn: CompareFn
): boolean => {
  return compareFn(itemValue, filterValue);
};

export const filterNested = <T>(
  item: T,
  path: string,
  filterValue: FilterValue,
  compareFn: CompareFn
): boolean => {
  const pathSegments = path.split('.'); // Split the path into segments
  let currentValue = item;

  for (const segment of pathSegments) {
    currentValue = get(currentValue, segment); // Traverse the object
    if (Array.isArray(currentValue)) {
      // If current value is an array, apply the remaining path to each item
      const remainingPath = pathSegments
        .slice(pathSegments.indexOf(segment) + 1)
        .join('.');
      return currentValue.some((arrItem) =>
        filterSingleItem(
          get(arrItem, remainingPath) as FilterValue,
          filterValue,
          compareFn
        )
      );
    }

    if (currentValue === undefined) return false;
  }

  return filterSingleItem(currentValue as FilterValue, filterValue, compareFn);
};

/**
 * Applies a filter condition (AND/OR) across multiple properties.
 *
 * @template T
 * @param {T} item - The item to apply the filter to.
 * @param {string[]} properties - The properties to filter by.
 * @param {FilterValue} filterValue - The value to filter by.
 * @param {CompareFn} compareFn - The comparison function to use.
 * @param {'AND' | 'OR'} condition - The filter condition (AND/OR).
 * @returns {boolean} Whether the item matches the filter condition.
 */
export const applyFilterCondition = <T>(
  item: T,
  properties: string[],
  filterValue: FilterValue,
  compareFn: CompareFn,
  condition: 'AND' | 'OR'
): boolean => {
  if (condition === 'OR') {
    return properties.some((prop) =>
      filterNested(item, prop, filterValue, compareFn)
    );
  } else if (condition === 'AND') {
    return properties.every((prop) =>
      filterNested(item, prop, filterValue, compareFn)
    );
  }
  return true; // Fallback if no valid condition
};

/**
 * Processes a filter on a given item.
 *
 * @template T
 * @param {T} item - The item to filter.
 * @param {FilterValue} filterValue - The filter value.
 * @param {string} compareFnKey - The comparison function key.
 * @param {FilterAliasMap} filterAliasMap - An optional map of filter aliases.
 * @param {string} filterAlias - An optional filter alias.
 * @param {string | string[]} property - The property or properties to filter.
 * @returns {boolean} Whether the item matches the filter.
 */
export const processFilter = <T>(
  item: T,
  filterValue: FilterValue,
  compareFnKey: string,
  filterAliasMap?: FilterAliasMap,
  filterAlias?: string,
  property?: string | string[]
): boolean => {
  if (!filterValue || (Array.isArray(filterValue) && !filterValue.length)) {
    return true; // Skip if the filter value is falsy or an empty array
  }
  const compareFn: CompareFn = compareFunctions[compareFnKey ?? 'eq'];

  // If filterAlias is present, use the mapped properties and condition
  const filterConfig = filterAlias ? filterAliasMap?.[filterAlias] : null;
  const properties = filterConfig ? filterConfig.properties : property;
  const condition = filterConfig ? filterConfig.condition : 'AND';

  if (Array.isArray(properties)) {
    // Handle multiple properties
    return applyFilterCondition(
      item,
      properties,
      filterValue,
      compareFn,
      condition
    );
  } else if (typeof properties === 'string') {
    // Handle single property case
    return filterNested(item, properties, filterValue, compareFn);
  }

  return true; // Skip filtering if no valid property
};
