import { createBrowserRouter } from 'react-router-dom';
import ErrorFallback from 'components/fallback/ErrorFallback';
import { MainLayout } from './components/layout/MainLayout';
import { managementRoutes } from 'pages/Management/Management.routes';
import {
  projectsRoutes,
  noSidebarProjectRoutes,
  newProjectRoute,
} from 'pages/Projects/Projects.routes';
import { assetsRoutes } from 'pages/Assets/Assets.routes';
import { SidebarLayout } from 'components/layout/SidebarLayout';

export const routes = [
  {
    path: '/login',
    lazy: () => import('./pages/Login'),
  },
  {
    element: <MainLayout />,
    path: '*',
    errorElement: <ErrorFallback />,
    children: [
      {
        index: true,
        lazy: () => import('./pages/Home'),
      },
      ...noSidebarProjectRoutes,
      newProjectRoute,
      {
        path: '',
        element: <SidebarLayout />,
        children: [
          ...projectsRoutes,
          ...managementRoutes,
          ...assetsRoutes,
          {
            path: 'analytics/*',
            lazy: () => import('./pages/Analytics'),
          },
          {
            path: 'glossary',
            lazy: () => import('./pages/Glossary'),
          },
        ],
      },
    ],
  },
];

export const browserRouter = createBrowserRouter(routes);
