// Copyright 2021 Greener Power Solutions                 //
// This file is part of the Greener Power Solutions,      //
// and may not be altered, copied, redistributed, or sold //
// without specific permission.                           //

import { createContext, useReducer } from 'react';

type TSidebarItem = {
  name: string;
  path: string;
  icon?: unknown; // should come up with a type for multiple sources of icons or standarize the use of icons in the app
};

type TReducerState = {
  items: TSidebarItem[];
  sidebarHeader: JSX.Element | null;
};

type TReducerAction = {
  type: string;
  payload: TReducerState;
};

type TContext = {
  sidebarState: TReducerState;
  dispatchSidebar: (opts: TReducerAction) => void;
};

const initialState: TReducerState = {
  items: [],
  sidebarHeader: null,
};

export const SidebarContext = createContext<Partial<TContext>>({
  sidebarState: initialState,
  dispatchSidebar: () => null,
});

function reducer(state: TReducerState, action: TReducerAction) {
  switch (action.type) {
    case 'REGULAR':
      return {
        ...state,
        items: action.payload.items,
        sidebarHeader: action.payload.sidebarHeader,
      };

    default:
      return { items: initialState.items, sidebarHeader: null };
  }
}

export function SidebarProvider({ children }: { children: JSX.Element }) {
  // noinspection JSCheckFunctionSignatures
  const [sidebarState, dispatchSidebar] = useReducer(reducer, initialState);

  return (
    <SidebarContext.Provider value={{ sidebarState, dispatchSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
}
